import { Box, Container, Divider, Grid2, Paper, Tabs, Typography, useMediaQuery, Tooltip } from '@mui/material';
import React, { useState, useEffect, useCallback } from 'react';
import { Outlet, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Header from '../components/header';
import StoreInfo from '../components/storeInfo';
import Navigator from '../components/navigator';
import { webCustomerBookingApi } from '../apis/web-customerBooking';
import { SplashScreen } from '../components/splash-screen';
import ConfirmationPolicy from '../components/confirmationPolicy';
import { useMounted } from '../hooks/use-mounted';
import Hours from '../components/hours';
import CustomizedSnackbars from '../components/customizedSnackbars';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { t, i18n } from 'i18next';
import Page404 from '../components/page404';
import AboutUsNew from './aboutUsNew';
import Pictures from './pictures';
import Booking from './booking';
import { styled } from '@mui/material/styles';
import theme from '../theme';
import { useTranslation } from 'react-i18next';

import { getVisitorToken } from '../utils/getVisitorToken';
import TagManager from 'react-gtm-module'


export const getUTMParams = () => {
    const params = new URLSearchParams(window.location.search);
    console.log('params', params);
    
    return {
      utm_source: params.get("utm_source") ?? "organic",
      utm_campaign: params.get("utm_campaign") ?? "",
      utm_medium: params.get("utm_medium") ?? "",
    };
  };
  

function StoreHomePage() {

    const detectDeviceLanguage = () => {
        const deviceLanguage = navigator.language || navigator.languages[0];
        const languageCode = deviceLanguage.split('-')[0];
        return ['en', 'da'].includes(languageCode) ? languageCode : 'da';
    };


  


    const { i18n } = useTranslation();

    const { state } = useLocation();
    const navigate = useNavigate();
    const { web_store_name } = useParams();
    const isMounted = useMounted();
    const isMobile = useMediaQuery('(max-width:600px)');
    const [tabValue, setTabValue] = useState("1");
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [policy, setPolicy] = useState('');
    const [loading, setLoading] = useState(true);
    const [storeDetails, setStoreDetails] = useState(null);
    const [selectedStep, setSelectedStep] = useState('category');

    const [locationLat, setLocationLat] = useState(null);
    const [locationLong, setLocationLong] = useState(null);
    const [error, setError] = useState(null);
    const [outletId, setOutletId] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [categorySelected, setSelectedCategory] = useState(null)
    const [serviceSelected, setSelectedService] = useState(null)

    const [language, setLanguage] = useState(() => {
        const storedLanguage = localStorage.getItem("webbooking-lang");
        if (storedLanguage) return storedLanguage;

        const detectedLanguage = detectDeviceLanguage();
        localStorage.setItem("webbooking-lang", detectedLanguage);
        return storedLanguage || detectedLanguage;
    });

   


    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };
    const [searchParams, setSearchParams] = useSearchParams();


      useEffect(() => {
        i18n.changeLanguage(language);   
      }, [language, i18n]);


    useEffect(() => {
        // const selectedLang = localStorage.getItem("webbooking-lang");

        // i18n.changeLanguage(selectedLang ?? 'da'); 
        

        if (web_store_name) {
            getStoreDetails(web_store_name, state);
        }
    }, [web_store_name]);


    // console.log('state', state);


    const callVisitorToken = async (outletId) => {
       
        const visitorToken = await getVisitorToken()
        let source = searchParams.get('source')

        // console.log('visitorToken', visitorToken);

        try {

        

          const response = await webCustomerBookingApi.trackVisitor(
            outletId,
            "PROFILE",
            source ? "WEBMARKETPLACE" : "DIRECTWEBSTORE",
            visitorToken
          );
          

          const { utm_medium, utm_source, utm_campaign } = getUTMParams();
          await webCustomerBookingApi.trackProfileVisit(
           {
            'event':'profileVisit',  utm_source, visitor_token:visitorToken, utm_campaign, utm_medium,  outlet_id: storeDetails.id
           }
          );

          console.log("response", response);

          // console.log('visitor token', response);
        } catch (err) {
          // console.log('visitor token error', response);
        }

    }

    useEffect(() => {
        if (storeDetails) {
            // console.log('storeDetails', storeDetails);

            callVisitorToken(storeDetails?.id);
        }
    }, [storeDetails]);

    // useEffect(()=>{

    //     const updateGTM=async(storeDetailsData)=>{

    //         console.log('here');
            
    //     const visitorToken = await getVisitorToken()

    //         if(gtm.initialized){
    //             console.log('in gtm intitalsi');
                
                
    //             // const { utm_medium, utm_source, utm_campaign } = getUTMParams();
    //             // gtm.push({
    //             //     event: "profileVisit",
    //             //     utm_source: utm_source,
    //             //     utm_medium,
    //             //     utm_campaign,
    //             //     visitor_token: visitorToken,
    //             //     outlet_id: storeDetailsData.id,
    //             // });
                
    //         }
    //     }

    //     if(storeDetails){

    //         updateGTM(storeDetails)
    //     }
    // },[storeDetails])


    const getStoreDetails = useCallback(async (storeName, state) => {
        // setLoading(true);
        try {
            const response = await webCustomerBookingApi.getWebCustomerBookingStoreDetails(storeName);
            const storeDetails = response?.data;
            const visitorToken = await getVisitorToken()

          
            const tagManagerArgs = {
                gtmId: storeDetails.gtmId,
                event:"storePageOpen"
              };
        
              TagManager.initialize(tagManagerArgs);

            setLoading(false);
            if (isMounted()) {

                setOutletId(storeDetails?.id);
                if (state?.service) {
                    const { group_id, id } = state.service
                    const { services } = storeDetails


                    const foundGroup = services.find((groupObj) => groupObj?.id == group_id)
                    const foundService = foundGroup.services.find((serviceObj) => serviceObj?.id == id)

                    // console.log('foundCategory', foundGroup);
                    // console.log('foundservice'), foundService;


                    setSelectedCategory(foundGroup ?? null)
                    setSelectedService(foundService ?? null)
                }
                setStoreDetails(storeDetails);




                const [longitude, latitude] = storeDetails?.geo_location
                    .replace("POINT (", "")
                    .replace(")", "")
                    .split(" ")
                    .map((coord) => parseFloat(coord));

                setLocationLat(latitude);
                setLocationLong(longitude);

                if (storeDetails?.policy && storeDetails?.policy.length > 0 && !storeDetails?.hide_marketplace_tnc) {
                    setPolicy(storeDetails?.policy);
                    setOpen(true);
                } else {
                    //setPolicy('');
                    setPolicy(null);
                    setOpen(false);
                };

                if (state?.service) {
                    setSelectedStep('category1')
                }

                if (location.pathname.endsWith('/about')) {
                    setTabValue("2");
                } else if (location.pathname.endsWith('/picture')) {
                    setTabValue("3");
                } else if (location.pathname.endsWith(`/klinik/${web_store_name}`)) {
                    setTabValue("1");
                }

                setLoading(false);
                // CallReceivedOutlined(storeDetails?.id);
            }

        } catch (err) {
            setLoading(false);
            setSnackbarOpen(true);
            //setError('Failed to load store details.');
            console.error('Error fetching store details:', err);
        }
    }, [isMounted]);

    useEffect(()=>{
        
        const updateProfile=async()=>{
            console.log('updateprofile called');
            
            if(storeDetails && !loading){
            const visitorToken = await getVisitorToken()
            const { utm_medium, utm_source, utm_campaign } = getUTMParams();


            TagManager.dataLayer({
                gtmId: storeDetails.gtmId,
                dataLayer: {'event':'profileVisit',  utm_source, utm_medium, utm_campaign, visitor_token:visitorToken,   outlet_id: storeDetails.id},
            })
        }}

        const trackStoreProfile=async()=>{
            console.log('updateprofile called');
            
            if(storeDetails && !loading){
            const visitorToken = await getVisitorToken()
            const { utm_medium, utm_source, utm_campaign } = getUTMParams();


            TagManager.dataLayer({
                gtmId: storeDetails.gtmId,
                dataLayer: {'event':'storeProfileVisit',  utm_source, utm_medium, utm_campaign, visitor_token:visitorToken,   outlet_id: storeDetails.id},
            })
        }}

        setTimeout(() => {
            updateProfile()
          }, 2000);

          setTimeout(() => {
            trackStoreProfile()
          }, 3000);


    },[loading])

    if (loading) return <SplashScreen />;
    if (error) return <Typography>{error}</Typography>;

    if (!storeDetails) {
        return <Page404 />
    }

    const placeID = storeDetails.google_placeid;
    // console.log("placeId>>>>>>>", placeID);

    const handleRevies = (place) => {
        // console.log('hello,', place);
        window.open(`https://search.google.com/local/reviews?placeid=${place}`, "_blank")
    }


    const handleChange = async(event, newValue) => {
        const visitorToken = await getVisitorToken()
        console.log('visitor token', visitorToken);
                const { utm_medium, utm_source, utm_campaign } = getUTMParams();

    
        
        if (newValue === "4") {
            handleRevies(placeID);
        } else {
            setSelectedStep('category')
            setTabValue(newValue);
           
            if (newValue === "2") {
                // gtm.push({'event':'aboutUsVisit',  'utm_source': 'organic', visitor_token:visitorToken,   outlet_id: storeDetails.id})
                
                // const tagManagerArgs = {
                //     dataLayer: {'event':'aboutUsVisit',  'utm_source': 'organic', visitor_token:visitorToken,   outlet_id: storeDetails.id},
                //     dataLayerName: 'aboutUsVisit'
                // }

                TagManager.dataLayer({
                    gtmId: storeDetails.gtmId,
                    dataLayer: {'event':'aboutUsVisit',  utm_source,utm_medium,utm_campaign,   visitor_token:visitorToken,   outlet_id: storeDetails.id},
                })
            }
           else if (newValue === "3") {
                // gtm.push({'event':'picturesVisit',  'utm_source': 'organic', visitor_token:visitorToken,   outlet_id: storeDetails.id})
                // const tagManagerArgs = {
                //     dataLayer: {'event':'picturesVisit',  'utm_source': 'organic', visitor_token:visitorToken,   outlet_id: storeDetails.id},
                //     dataLayerName: 'picturesVisit'
                // }

                TagManager.dataLayer({
                    gtmId: storeDetails.gtmId,
                    dataLayer: {'event':'picturesVisit', utm_source,utm_medium,utm_campaign, visitor_token:visitorToken,   outlet_id: storeDetails.id},
                })

                // TagManager.dataLayer(tagManagerArgs)
           }
        }
    };

    const StyledTabs = styled(({ className, ...other }) => {
        return (
            <Tabs
                {...other}
                classes={{
                    root: className,
                    flexContainer: "flexContainer",
                    indicator: "indicator"
                }}
                variant="fullWidth"
                TabIndicatorProps={{ children: <span /> }}
                centered
            />
        );
    })({
        "& .indicator": {
            display: "flex",
            justifyContent: "center",
            backgroundColor: "transparent",
            height: "4px",

            "& > span": {
                maxWidth: 120,
                width: "100%",
                backgroundColor: theme.palette.primary.main,
                borderTopLeftRadius: "4px",
                borderTopRightRadius: "4px",
            }
        },
        "& .flexContainer": {
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center"
            // width:'fit-content'
        },
        '&:hover': { color: 'primary.main' },
        '& .Mui-focused': { color: 'primary.main' },
    });


    return (
        <Container maxWidth='100%' disableGutters sx={{ height: '100vh', pl: 0, pr: 0, mb: 10 }}>

            <CustomizedSnackbars
                open={snackbarOpen}
                message={"Failed to load Data..."}
                onClose={handleSnackbarClose}
            />



            {storeDetails && (
                <>
                    <Header storeDetails={storeDetails} from={state?.from ?? null} />

                    <StoreInfo storeDetails={storeDetails} />

                    {storeDetails?.badges.length > 0 &&
                        <Grid2 container spacing={2} justifyContent="flex-start" sx={{ mt: 2, pl: isMobile ? 2 : 5, pr: isMobile ? 2 : 5 }}>
                            {storeDetails?.badges.map((badge) => (
                                <Grid2 item key={badge.id}>
                                    <Tooltip title={badge.description || 'No description available'} arrow>
                                        <Paper
                                            sx={{
                                                width: 'auto',
                                                height: 30,
                                                borderRadius: '13px',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                // cursor: 'pointer',
                                                overflow: 'hidden',
                                                boxShadow:'none'
                                            }}
                                            onClick={() => {
                                                console.log(`Badge clicked: ${badge.name}`);
                                            }}
                                        >
                                            <img
                                                src={`https://fiind-dev.s3.amazonaws.com/${badge.image}`}
                                                style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    maxHeight: '100%',
                                                    objectFit: 'cover',
                                                }}
                                            />
                                        </Paper>
                                    </Tooltip>
                                </Grid2>
                            ))}
                        </Grid2>
                    }

                    <TabContext value={tabValue}  >

                        <Box sx={{ borderBottom: 1, borderColor: 'divider', }} ml={0} mr={0} pl={0} pr={0} mt={1}>
                            {/* <TabList variant='fullWidth' centered onChange={handleChange} aria-label="Tabs with routing"> */}
                            <StyledTabs value={tabValue} onChange={handleChange} aria-label="Tabs with routing">
                                <Tab label={t('booking')} value="1" disableRipple={true} sx={{
                                    textTransform: 'none', fontSize: { xs: '0.90rem', md: '1.1rem' }, mx: { sm: 3, md: 8, lg: 13 }, fontWeight: tabValue === "1" ? 700 : 400, ml: 0, mr: 0,
                                }} />
                                {storeDetails?.hide_marketplace_aboutus ? null :
                                    (
                                        <Tab label={t('aboutUs')} value="2" disableRipple={true} sx={{ textTransform: 'none', fontSize: { xs: '0.90rem', md: '1.1rem' }, mx: { sm: 3, md: 8, lg: 13 }, fontWeight: tabValue === "2" ? 700 : 400, ml: 0, mr: 0 }} />
                                    )
                                }
                                {storeDetails?.hide_marketplace_photos ? null :
                                    (
                                        <Tab label={t('pictures')} value="3" disableRipple={true} sx={{ textTransform: 'none', fontSize: { xs: '0.90rem', md: '1.1rem' }, mx: { sm: 3, md: 8, lg: 13 }, fontWeight: tabValue === "3" ? 700 : 400, ml: 0, mr: 0 }} />
                                    )
                                }
                                {placeID === null || placeID === undefined ? null : (
                                    <Tab label={t('reviews')} value="4" disableRipple={true} sx={{ textTransform: 'none', fontSize: { xs: '0.90rem', md: '1.1rem' }, mx: { sm: 3, md: 8, lg: 13 }, fontWeight: tabValue === "4" ? 700 : 400, ml: 0, mr: 0 }} />
                                )}
                            </StyledTabs>
                            {/* </TabList> */}
                        </Box>

                    </TabContext>


                    <>
                        <Grid2 container sx={{ display: 'flex', flexDirection: 'row', pl: { xs: 2, md: 8 }, pr: { xs: 2, md: 8 }, mb: '160px' }}>
                            <Grid2 item size={{ xs: 12, lg: 8 }} sx={{ display: 'flex', flexDirection: 'row', }} >
                                {

                                    tabValue == 1 &&
                                    <Booking
                                        web_store_name={web_store_name}
                                        selectedStep={selectedStep}
                                        setSelectedStep={setSelectedStep}
                                        outletId={outletId}
                                        setOutletId={setOutletId}
                                        locationLat={locationLat}
                                        setLocationLat={setLocationLat}
                                        locationLong={locationLong}
                                        setLocationLong={setLocationLong}
                                        storeDetails={storeDetails}
                                        open={open}
                                        setOpen={setOpen}
                                        categorySelected={categorySelected}
                                        serviceSelected={serviceSelected}
                                        TagManager={TagManager}
                                    // context={{
                                    //     web_store_name,
                                    //     selectedStep,
                                    //     setSelectedStep,
                                    //     outletId,
                                    //     setOutletId,
                                    //     locationLat,
                                    //     setLocationLat,
                                    //     locationLong,
                                    //     setLocationLong,
                                    //     storeDetails,
                                    //     open,
                                    //     setOpen,
                                    //     categorySelected,
                                    //     serviceSelected
                                    // }}
                                    />
                                }
                                {
                                    tabValue == 2 &&
                                    <AboutUsNew storeDetails={storeDetails} />
                                }
                                {
                                    tabValue == 3 &&
                                    <Pictures storeDetails={storeDetails} />
                                }
                            </Grid2>
                            <Grid2 item size={{ xs: 0, lg: 4 }} sx={{ display: { xs: 'none', lg: 'flex' }, flexDirection: 'row', justifyContent: 'flex-end', alignContent: 'center', marginTop: 10 }}>
                                {!isMobile &&
                                    <Hours storeDetails={storeDetails} />
                                }
                            </Grid2>
                        </Grid2>
                    </>

                    {
                        open && <ConfirmationPolicy open={open} handleClose={handleClose} policy={policy} />
                    }
                </>
            )}


        </Container>
    );
}

export default StoreHomePage;
