import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { RouterProvider } from 'react-router-dom';
import router from './router';
import { ThemeProvider } from '@emotion/react';
import theme from './theme';
import '../src/i18n';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import { LoadScript } from '@react-google-maps/api';
import { SplashScreen } from './components/splash-screen';




// useEffect(() => {
//   const storedLang = localStorage.getItem('webbooking-lang') || 'en';
//   i18next.changeLanguage(storedLang);
// }, []);
const root = ReactDOM.createRoot(document.getElementById('root'));

const Main = () => {

  return(
<ThemeProvider theme={theme}>
    <I18nextProvider i18n={i18next}>
      <LoadScript
        googleMapsApiKey="AIzaSyCI2u5iSXIqE6wnNPAIjXGpT-SGc2c22SI"
        libraries={['places']} loadingElement={<div 
        //style={{ display: 'none' }} 
        >
          <SplashScreen />
        </div>}>
        {/* <React.StrictMode> */}

        <RouterProvider router={router} />

        {/* </React.StrictMode> */}
      </LoadScript>

    
      {/* </React.StrictMode> */}
    </I18nextProvider>
  </ThemeProvider>
  )
}
root.render(<Main/>);
