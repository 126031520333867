import React from 'react';
import { Card, CardContent, Typography, Box, Button, useMediaQuery, Stack } from '@mui/material';
import { Rating } from 'react-simple-star-rating';
import { t } from 'i18next';
import specialOffertag from '../assets/images/tag_(2)specialoffer.png';
import theme from '../theme';
import locationIcon from '../assets/images/location(1).png';
import { Navigate, useNavigate } from 'react-router-dom';

const StoreCard = ({ store, checkOption, firstRow , searchData , selectedTab}) => {

    // console.log('checkOption ', checkOption)
    // console.log('firstRow ', firstRow)

    // console.log('store', store);
    const navigate = useNavigate();

    // console.log('HERE IT IS ', checkOption)
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { categories } = store;
    const categoriesCount = categories.length;
      
    

    return (
        <Card sx={{ width: isSmallScreen ? 'auto' : 'auto', height: 230, padding: 0, flexDirection: 'column', borderRadius: 3, boxShadow: 4 }}>

            <CardContent sx={{ flexDirection: 'column', height: '100%', padding: 0 }}>

                <Stack flex={1} flexDirection={'row'} justifyContent={'space-between'} style={{ display: 'flex', flexDirection: 'row', }}>

                    <Box sx={{ height: '50%', padding: 1, pb: 0, overflow: 'hidden', maxWidth: '80%', pr: 0 }}>

                        <Typography variant="body1" noWrap sx={{
                            fontWeight: 'bold', overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            fontSize: '0.85rem',
                            pr: 0,
                            color: '#000000'
                        }}>
                            {store.name}
                        </Typography>

                        <Rating initialValue={store.avg_rating} fillColor='#f1a545' readonly={true} size={16} />
                        {store.avg_rating > 0 && (
                            <Typography variant="caption" sx={{ ml: 0.5 }}>
                                {store.avg_rating.toFixed(1)}
                            </Typography>
                        )}

                        <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: 0.2 }}>
                            <Box
                                sx={{
                                    width: "auto",
                                    maxWidth: '100%',
                                    height: "auto",
                                    border: '1px solid #A79C92',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderRadius: 2,
                                    marginRight: 1,
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap'
                                }}
                            >
                                <Typography variant="caption" sx={{ paddingRight: 1, paddingLeft: 1, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', color: '#a89c91' }}>
                                    {categoriesCount === 1 ? (
                                        categories[0].category
                                    ) : categoriesCount > 1 ? (
                                        categories[0].category
                                    ) : (
                                        t('NoCategories')
                                    )}
                                </Typography>
                            </Box>

                            {categoriesCount > 1 && (
                                <Box
                                    sx={{
                                        width: "auto",
                                        height: "auto",
                                        border: '1px solid #A79C92',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        borderRadius: 2,
                                        marginRight: 1,
                                    }}
                                >
                                    <Typography variant="caption" sx={{ paddingRight: 1, paddingLeft: 1, color: '#a89c91' }}>
                                        {`+${categoriesCount - 1}`}
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                    </Box>

                    <Box sx={{ height: '50%', display: 'flex', justifyContent: 'flex-end', overflow: 'hidden', maxWidth: '30%', pr: 0, pl: 0 }}>
                        {(checkOption?.bottomText == "Special offers" || firstRow == 'Special offers') ? (
                            <img
                                src={specialOffertag}
                                alt="IconOne"
                                style={{
                                    margin: 10,
                                    width: '28%',
                                    height: '28%',
                                }}
                            />
                        ) : (
                            null
                            // <Typography variant="body2" noWrap sx={{
                            //     maxWidth: '100%',
                            //     overflow: 'hidden',
                            //     textOverflow: 'ellipsis',
                            //     whiteSpace: 'nowrap',
                            //     color: store.open ? '#00a518' : 'red',
                            //     padding: 1,
                            //     fontSize: '0.75rem',
                            //     fontWeight: 700,
                            //     pl: 0
                            // }}>
                            //     {store.open ? `${t('Open')}` : `${t('Close')}`}
                            // </Typography>
                        )
                        }
                    </Box>
                </Stack>

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '100%', paddingTop: 5, paddingBottom: 5 }}>
                    <Box
                        component="img"
                        src={locationIcon}
                        alt="service Image"
                        sx={{
                            width: '16px',
                            height: '16px',
                            objectFit: 'contain',
                            objectPosition: 'center',
                            display: 'block',
                            marginLeft: 1
                        }}
                    />
                    <Typography
                        variant="caption"
                        noWrap
                        sx={{
                            paddingLeft: 0.5,
                            width: '60%',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            display: 'block',
                            color: '#545454'
                        }}
                    >
                        {store.address}
                    </Typography>

                    <Box sx={{ width: '40%', display: 'flex', justifyContent: 'flex-end', overflow: 'hidden' }}>
                        {checkOption?.bottomText == "Special offers" || firstRow == 'Special offers' ?
                            null
                            // <Typography variant="body2" noWrap sx={{
                            //     maxWidth: '100%', overflow: 'hidden',
                            //     textOverflow: 'ellipsis',
                            //     whiteSpace: 'nowrap',
                            //     color: store.open ? '#00a518' : 'red',
                            //     marginRight: 2
                            // }}>
                            //     {store.open ? `${t('Open')}` : `${t('Close')}`}
                            // </Typography>
                            :
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                <Box
                                    component="img"
                                    src={locationIcon}
                                    alt="service Image"
                                    sx={{
                                        width: '16px',
                                        height: '16px',
                                        objectFit: 'contain',
                                        objectPosition: 'center',
                                        display: 'block',
                                    }}
                                />

                                <Typography variant="caption" noWrap sx={{ paddingLeft: 0.5, mr: 0.5, color: '#545454' }} >
                                    {/* {store?.distance != null
                                        ? (store?.distance > 1000
                                            ? `${(store?.distance / 1000).toFixed(2)} km`
                                            : `${store?.distance.toFixed(2)} m`)
                                        : ''} */}
                                    {store?.distance != null
                                        ? (store?.distance > 1000
                                            ? `${(store?.distance / 1000).toFixed(1)} km`
                                            : `${store?.distance.toFixed(1)} m`)
                                        : ''}
                                </Typography>
                            </div>
                        }
                    </Box>
                </div>

                <Box sx={{ display: 'flex', width: '100%', height: '100%', justifyContent: 'center' }}>

                {/* <Box
                        component="img"
                        src={https://fiind-dev.s3.amazonaws.com/${store?.image}}
                        alt="Store Image"
                        sx={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            objectPosition: 'center',
                            display: 'block',
                            backgroundColor: 'black',
                        }}
                    />

                    <Box
                        sx={{
                            display: 'flex',
                            position: 'absolute',
                            // top: 0,
                            // marginTop: '130px',
                            marginLeft: { xs: '0', sm: '0', md: '24px' },
                            width: 'auto',
                            left: 0,
                            right: 0,
                            bottom: 0,
                            //height: 127,
                            height: 127.8,
                            alignSelf: 'center',
                            color: 'white',
                            backgroundColor: 'rgba(0, 0, 0, 0.3)',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderBottomLeftRadius: 12,
                            borderBottomRightRadius: 12,
                        }}
                    /> */}

                    <Box
                        sx={{
                            position: 'relative', 
                            width: '100%',
                            height: '100%',
                            
                        }}
                    >
                        <Box
                            component="img"
                            src={`https://fiind-dev.s3.amazonaws.com/${store?.image}`}
                            alt="Store Image"
                            sx={{
                                width: '100%',
                                height: 'auto', 
                                objectFit: 'cover',
                                objectPosition: 'center',
                                display: 'block',
                                backgroundColor: 'black',
                            }}
                        />
                        <Box
                            sx={{
                                position: 'absolute', 
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                color: 'white',
                                backgroundColor: 'rgba(0, 0, 0, 0.3)',
                                borderBottomLeftRadius: 12,
                                borderBottomRightRadius: 12,
                            }}
                        />
                    </Box>

                    {(checkOption?.id == 1 || firstRow === 'Special offers') && (
                        <Box
                            sx={{
                                display: 'flex',
                                position: 'absolute',
                                top: '53%',
                                right: 5,
                                color: 'white',
                                fontWeight: 'bold',
                                textAlign: 'center',
                                padding: '5px',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                width: 'auto',
                                height: '12%',
                                backgroundColor: '#E8E7E7',
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderRadius: 2,
                                pt: 0,
                                pb: 0
                            }}
                        >
                            <Typography
                                variant='body2'
                                sx={{
                                    fontSize: '0.85rem',
                                    fontWeight: '400',
                                    color: 'black',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                    ml: 0.5,
                                    mr: 0.5,
                                    mt: 0,
                                    mb: 0
                                }}
                            >
                                {`${store?.no_of_services} ${store?.no_of_services > 1 ? t('offers') : t('offer')}`}
                            </Typography>
                        </Box>
                    )}

                    {store?.web_store_name &&
                        <Box sx={{ position: 'absolute', zIndex: 1, width:'100%', height: 40, display: 'flex', justifyContent: 'center', alignItems: 'center', alignSelf: 'end', bottom: 5 }}>
                            <Button
                                variant="contained"
                                disableElevation
                                onClick={() => {
                                    localStorage.setItem("searchData", JSON.stringify(searchData)); 
                                    localStorage.setItem("selectedTab", selectedTab); 
                                    navigate(`/klinik/${store?.web_store_name}?source=marketplace`, { state: { from: 'marketPlace' } })}}
                                sx={{
                                    //position: 'absolute',
                                    //bottom: 10,
                                    // left: '47%',
                                    // transform: 'translate(-46%, -50%)',
                                    //zIndex: 1,
                                    width: '80%',
                                    borderRadius: 5,
                                    color: 'white',
                                    border: '1px solid white',
                                    backgroundColor: checkOption?.bottomText == "Special offers" || firstRow == 'Special offers' ? '#df013b' : theme.palette.primary.main,
                                    textTransform: 'none',
                                    fontWeight: 700,
                                    fontSize: '0.90rem',
                                    height: 30,
                                }}
                            >
                                {checkOption?.bottomText == "Special offers" || firstRow == 'Special offers' ? `${t('upto')} ${Math.round(store?.special_services_max_discount)}% ${t('discount')}` : `${t('visitClinic')}`}
                            </Button>
                        </Box>
                    }
                </Box>
            </CardContent>
        </Card>
    );
};

export default StoreCard;
