import { apiService } from '../services/apiServices';

class WebCustomerBookingApi {

    async getWebCustomerBookingStoreDetails(web_store_name) {
        return new Promise((resolve, reject) => {
            //console.log('inside api ', web_store_name);
            try {
                const storeDetails = apiService.get(`/web?web_store_name=${web_store_name}`);                                
                resolve(storeDetails);
            } catch (err) {
                console.error('[get All service group Api]: ', err);
                reject(new Error('Internal server error'));
            }
        });
    }

    async getTiming(serviceId, employeeId, date, outletId, considerEmployeeHours) {        
        return new Promise((resolve, reject) => {
            try {
                console.log('employeeId', employeeId);
                
                let timingDetails = apiService.get(`/web/timings?service_id=${serviceId}&date=${date}&outlet_id=${outletId}&consider_employee_hours=${considerEmployeeHours}`);    
                if(employeeId != undefined){
                     timingDetails = apiService.get(`/web/timings?service_id=${serviceId}&employee_id=${employeeId}&date=${date}&outlet_id=${outletId}&consider_employee_hours=${considerEmployeeHours}`);    

                }            
                resolve(timingDetails);
            } catch (err) {
                console.log('HERE IS ERROR ', err);
                console.error('[get All service group Api]: ', err);
                reject(new Error('Internal server error'));
            }
        });
    }

    async requestOTP({name, country_code, phone_number, email, address, note, marketing_permission, birthDay, cpr, outlet_id}) {
        console.log("dataa ===" , name, country_code, phone_number, email, address, note, marketing_permission, birthDay, cpr);
        
        return new Promise((resolve, reject) => {
            try {
                const resp = apiService.post(`/web/getOtp?outlet_id=${outlet_id}`, { name, country_code, phone_number, email, address, note, marketing_permission,  birthDay   , cpr });
                resolve(resp);
            } catch (err) {
                console.log('[Request OTP]: ', err);
                reject(new Error('Internal server error'));
            }
        });
    }

    async verifyOTP(country_code, phone_number, otp) {
        return new Promise((resolve, reject) => {
            try {
                const resp = apiService.post(`/web/verifyOTP`, { country_code, phone_number, otp });
                resolve(resp);
            } catch (err) {
                console.log('[Verify OTP]: ', err);
                reject(new Error('Internal server error'));
            }
        });
    }

    async confirmBooking(outletId, saveToken, booking_date, customer_name, customer_phone_number, note, employee_id, service_id, time_slot, total_amount, source, visitor_token, customer_email, customer_address, customer_birthday, customer_cpr) {
        return new Promise((resolve, reject) => {
            try {
                const resp = apiService.post(`/web/book?outlet_id=${outletId}&verification_token=${saveToken}`, { booking_date, customer_name, customer_phone_number, note, employee_id, service_id, time_slot, total_amount, source, visitor_token , customer_email, customer_address, customer_birthday, customer_cpr });
                resolve(resp);
            } catch (err) {
                console.log('[Confirm Booking]: ', err);
                reject(new Error('Internal server error'));
            }
        });
    }

    async trackVisitor(outletId, page, source, visitor_token) {
        return new Promise((resolve, reject) => {
            try {
                const resp = apiService.post(`/web/track/visit`, { outlet_id: outletId, page:page, source:source , visitor_token: visitor_token });
                resolve(resp);
            } catch (err) {
                console.log('[track visitor]: ', err);
                reject(new Error('Internal server error'));
            }
        });
    }

    async trackProfileVisit({outlet_id, visitor_token, event, utm_source, utm_campaign, utm_medium}) {
        return new Promise((resolve, reject) => {
            try {
                const resp = apiService.post(`/web/save_gtm_data`, {outlet_id, visitor_token, event, utm_source, utm_campaign, utm_medium });
                resolve(resp);
            } catch (err) {
                console.log('[track visitor]: ', err);
                reject(new Error('Internal server error'));
            }
        });
    }
}

export const webCustomerBookingApi = new WebCustomerBookingApi();
