import styled from '@emotion/styled';
import { Box, Grid2, Paper, Typography, useMediaQuery, Radio, Button, Chip, Divider, Stack, Tooltip } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import theme from '../theme';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import { t } from 'i18next';
import { format, isToday, isTomorrow, parseISO, differenceInSeconds } from 'date-fns';
import { formatAmount } from '../utils/format-amount';

function ServiceCardNew(props) {
  const { type, onchange, checked, specialprice, cancelationprice, countdownSeconds, cancellation_offer_slot_start, cancellation_offer_slot_end, service } = props;

  const isMobile = useMediaQuery('(max-width:600px)');
  const isTablet = useMediaQuery('(min-width: 768px) and (max-width: 1024px)' );

  const [showMore, setShowMore] = useState(false);
  const [timeLeft, setTimeLeft] = useState(countdownSeconds);
  const [isVisible, setIsVisible] = useState(true);
  const [cancellationTimeLeft, setCancellationTimeLeft] = useState(0);
  const [timeLeftString, setTimeLeftString] = useState('');
  const from_price = service?.is_price_starts_with;

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: isMobile ? theme.spacing(1) : theme.spacing(0),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),

    textAlign: 'center',
    color: theme.palette.text.secondary,
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
    borderRadius: '10px',
    cursor: 'pointer',
  }));



  const toggleShowMore = () => {
    setShowMore((prev) => !prev);
  };


  // useEffect(() => {
  //   if (type === 'Cancellation Offers') {
  //     const startTime = new Date(cancellation_offer_slot_start);
  //     const endTime = new Date(cancellation_offer_slot_end);
  //     const now = new Date();

  //     const remainingTime = Math.max(0, differenceInSeconds(endTime, now));
  //     setCancellationTimeLeft(remainingTime);

  //     // Formatting the date and time for display
  //     if (remainingTime > 0) {
  //       const displayDate = format(endTime, 'eeee');
  //       const displayTime = format(endTime, 'HH:mm');

  //       if (isToday(endTime)) {
  //         setTimeLeftString(`Today at ${displayTime}`);
  //       } else if (isTomorrow(endTime)) {
  //         setTimeLeftString(`Tomorrow at ${displayTime}`);
  //       } else {
  //         setTimeLeftString(`${displayDate} at ${displayTime}`);
  //       }
  //     } else {
  //       setTimeLeftString('Offer has expired');
  //     }
  //   }
  // }, [type, cancellation_offer_slot_start, cancellation_offer_slot_end]);

  // useEffect(() => {
  //   const storedData = localStorage.getItem('serviceTimer');
  //   const now = Date.now();

  //   if (storedData) {
  //     const { startTime, duration } = JSON.parse(storedData);
  //     const endTime = startTime + duration * 1000;
  //     const remainingTime = Math.max(0, Math.floor((endTime - now) / 1000));

  //     setTimeLeft(remainingTime);
  //   } else {
  //     const newStartTime = Date.now();
  //     localStorage.setItem('serviceTimer', JSON.stringify({ startTime: newStartTime, duration: countdownSeconds }));
  //     setTimeLeft(countdownSeconds);
  //   }
  // }, [countdownSeconds]);



  if (!isVisible) return null; // Return null if the card is not visible




  return (


    <Item
      key={service?.id}
      sx={{
        border: `3px solid ${theme.palette.primary.main}`,
        borderRadius: '20px',
        width: '100%'
        // width: { sm: '390px', md: '550px', xl: '760px' },

      }}
      onClick={onchange}
    >

      <Grid2
        container
        size={12}
        alignItems="center"
        // justifyContent="space-between"
        // gap={2}
        sx={{ width: '100%', mt: 0, pt: 0, mb: 0, pb: 0 }}
      >
        <Grid2 size={{ md: 9, sm: 12, xs: 12 }} sx={{ textAlign: 'left', }}>
          <Stack justifyContent={'start'} alignItems={'center'} flexDirection={'row'} borderBottom={isMobile ? `1px solid ${theme.palette.primary.main}` : null} sx={{ ml: { xs: -1, sm: 0 }, mr: { xs: -1, sm: 0 }, mt: { xs: -1, sm: 0 } }}>
            {
              <Radio
                sx={{ color: 'primary.main', pl: 0, ml: 1, mt: 0, display: { md: 'none', xs: 'block', sm: 'block' } }}

                checked={checked}
                value={`service${service.id}`}
                name="service-radio"
              />
            }
            <Tooltip title={service?.name} arrow>
              <Typography
                variant='subtitle1'
                sx={{
                  whiteSpace: 'nowrap',
                  fontWeight: 'bold',
                  color: 'primary.text',
                  WebkitLineClamp: 1,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width:{xs:'100%' , md:'85%'}
                }}
                
              >
               {/* {type === 'cancellationOffers' || type === 'specialOffers' ? `(${service?.name.length})` : ''} */}
                {service?.name}
              </Typography>
            </Tooltip>
          </Stack>

          <Stack
            alignItems="left"
            textAlign="left"
            justifyContent="start"
            display={(type === 'specialOffers' || type === 'cancellationOffers') ? 'flex' : 'none'}
            sx={{
              width: '100%',
              mt: 1
            }}
          >
            <Typography
              variant="subtitle1"
              component="div"
              sx={{ color: 'text.secondary' }}
            >{service?.description}</Typography>
          </Stack>
        </Grid2>


        <Grid2 size={{ md: 1, sm: 6, xs: 6 }} sx={{ mt: { xs: 1, md: 0 } }}>
          <Stack sx={{ justifyContent: { xs: 'start', md: 'center' } }} alignItems={'center'} flexDirection={'row'}>

            <Typography sx={{ whiteSpace: 'nowrap', mr: { xs: 2, md: 10 } }} component="div" variant="body1"  >
              {service?.duration_min ?  service.duration_text:''}
            </Typography>
          </Stack>
        </Grid2>
        <Grid2 size={{ md: 2, sm: 6, xs: 6 }} sx={{ mt: { xs: 1, md: 0 }, borderLeft: type === 'specialOffers' || type === 'cancellationOffers' ? '2px solid orange' : '' }}>
          <Stack justifyContent={'end'} alignItems={'center'} flexDirection={'row'} marginLeft={2} >

            <Typography variant='subtitle1'
              sx={{ whiteSpace: 'nowrap', fontWeight: 'bold', color: 'primary.text' }}
            >
               {from_price && `${t('From')} `}
              {
                formatAmount(service?.price, false)
              }
            </Typography>
            {
              <Radio
                sx={{ color: 'primary.main', display: { md: 'flex', xs: 'none', sm: 'none', top: -2, right: -12 }, justifyContent: 'end', alignItems: 'center' }}

                checked={checked}
                value={`service${service.id}`}
                name="service-radio"
              />
            }
          </Stack>
        </Grid2>


        {/* 
              <Grid2  xs={1} sx={{  flexWrap: type === 'Special Services' || type === 'Cancellation Offers' ? 'wrap' : 'nowrap', flexDirection: type === 'Special Services' || type === 'Cancellation Offers' ? 'column' : '', display: 'flex', justifyContent: 'end', borderLeft: type === 'Special Services' || type === 'Cancellation Offers' ? '2px solid orange' : '', paddingBottom: type === 'Special Services' || type === 'Cancellation Offers' ? '-15px' : '0' }}>
                <Radio
                  sx={{ color: 'primary.main', marginTop: '-10px', display: 'flex', justifyContent: 'end' }}
                  checked={checked}
                  value={value}
                  name="service-radio"
                />

              </Grid2> */}
      </Grid2>


      <Grid2

        flexDirection='column'
        alignItems="left"
        textAlign="left"
        justifyContent="start"
        display={(type === 'specialOffers' || type === 'cancellationOffers') ? 'none' : 'flex'}
        sx={{
          width: '100%',
          mt: 0,
          pt: 0,
          mb: 2

        }}
      >
        <Typography
          variant="subtitle2"
          component="div"
          sx={{
            color: 'text.secondary', display: showMore ? 'block' : '-webkit-box',
            WebkitLineClamp: isMobile ? 2 : '',
            WebkitBoxOrient: 'vertical',
            overflow: showMore ? 'visible' : 'hidden',
            textOverflow: 'ellipsis',
          }}
        >{service?.description}
          {/* <span><Typography>  {showMore ? t('ShowLess') : t('ReadMore')}s</Typography></span> */}

          {/* && (
                <Button
                  variant="text"
                  sx={{ textTransform: 'none', display: 'flex', alignContent: 'left', fontSize: '12px', marginLeft: '0', padding: 0, }}
                  onClick={(event) => { event.stopPropagation(); toggleShowMore(); }}
                >
                  {showMore ? t('ShowLess') : t('ReadMore')}
                </Button>
              )} */}
        </Typography>
        {isMobile && service?.description?.length > 50 && (
          <Typography
            onClick={(event) => { event.stopPropagation(); toggleShowMore(); }}
            sx={{
              color: theme.palette.primary.main,
              cursor: 'pointer',
            }}
          >
            {showMore ? t('ShowLess') : t('ReadMore')}
          </Typography>
        )}

      </Grid2>

    </Item>


  );
}

export default ServiceCardNew;
