import { v4 as uuidv4 } from 'uuid';
export const getVisitorToken = async () => {
    let visitorToken = localStorage.getItem("visitorToken")
    if (visitorToken) {
        console.log('visitorToken in web booking');
    } else {
        const newVToken = uuidv4();

        localStorage.setItem("visitorToken", newVToken);
        visitorToken = newVToken
    }

    return visitorToken
}